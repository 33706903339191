<template>
  <div>
    <div v-if="loader && !posts.length" class="d-flex justify-content-center">
      <ChLoading size="lg" class="spinner" />
    </div>
    <div v-else>
      <div v-if="posts && posts.length">
        <MasonaryLayout :cols="{ default: 4, 700: 3, 400: 2, 1000: 3 }" :gutter="6">
          <div v-for="(item, index) in posts" :key="index">
            <post-card
              :post="item"
              :blab-replies-count="get(blabRepliesCount, `${get(item, 'entity.id')}.blab_replies_count`) || 0"
              :indent-reaction-right="index % 2 === 1"
            />
          </div>
        </MasonaryLayout>

        <client-only>
          <div class="d-flex justify-content-center my-3">
            <infinite-loading v-if="totalPosts !== 0" @infinite="infiniteLoadHandler">
              <template #complete>
                <span></span>
              </template>
              <template #spinner>
                <div class="d-flex justify-content-center">
                  <ChLoading size="sm" class="spinner" />
                </div>
              </template>
            </infinite-loading>
          </div>
        </client-only>

        <p v-if="totalPosts === posts.length">End of list</p>
      </div>
      <div v-else class="no-data">No posts here yet</div>
    </div>

    <blab-details-modal
      :is-open="openBlabAsModal"
      :blabid="blabid"
      @dismiss-modal="closeBlabAsModal"
      @edit="openEditBlab"
      @profile="openProfile"
      @details="openDetailsPage"
      @afterReaction="(reactionResp: any) => reaction(blabIndex, reactionResp)"
    />
  </div>
</template>

<script setup lang="ts">
import InfiniteLoading from 'v3-infinite-loading';
import PostCard from './PostCard.vue';
import BlabDetailsModal from '@/shared/modals/BlabDetailsModal.vue';
import { getCountofBlabReplies } from '@/shared/actions/blabs';
import { Blab } from '@/shared/types/static-types';
import MasonaryLayout from '@/shared/components/MasonaryLayout.vue';

const props = defineProps({
  posts: {
    type: Array<Blab>,
    default: () => [],
    required: true,
  },
  totalPosts: {
    type: Number,
    default: () => 0,
  },

  loader: {
    type: Boolean,
    default: false,
  },
});

const totalPosts = toRef(props, 'totalPosts');
const emits = defineEmits(['loadMore']);
const router = useRouter();
const posts = toRef(props, 'posts');
const blabRepliesCount = ref({});
const openBlabAsModal = ref(false);
const blabid = ref('');
const blabIndex = ref(-1);

watch(posts, () => {
  if (posts.value.length) {
    fetchBlabReplies();
  }
});

const closeBlabAsModal = () => {
  openBlabAsModal.value = false;
  fetchBlabReplies();
  nextTick(() => (blabid.value = ''));
};

const openEditBlab = (edit: boolean, id: string) => {
  if (edit) {
    blabid.value = '';
    openBlabAsModal.value = false;
    router.push({ name: 'edit-post', params: { id } });
  }
};

const openProfile = (openprofile: boolean) => {
  blabid.value = '';

  if (openprofile) {
    openBlabAsModal.value = false;
  }
};

const openDetailsPage = () => {
  openBlabAsModal.value = false;
  router.push({ name: 'post-details', params: { id: blabid.value } });
};

const infiniteLoadHandler = ($state: any) => {
  if (posts.value.length < totalPosts.value) {
    emits('loadMore');
  } else {
    $state.complete();
  }
};

const fetchBlabReplies = async () => {
  blabRepliesCount.value = {};

  const resp = await getCountofBlabReplies(map(posts.value, 'id'));

  blabRepliesCount.value = keyBy(resp, 'blab');
};

const reaction = (index: number, reactResp: any) => {
  if (posts.value.length) {
    posts.value[index] = { ...posts.value[index], ...reactResp.updatedReactionsData };
  }
};
</script>

<style lang="sass" scoped>
.masonry-container
  margin: 0 auto
</style>
